import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";
import { MediaAuthContext } from "../../store/context/media-auth-context";
import { LayoutContext } from "../../store/context/layout-context";

const DealsBannerContainer = (props) => {
  const {
    alwaysShowDeals,
    category,
    children,
    closureOnlyCross,
    dealsSection,
    dealsUrl,
    dealsVersion,
    disableRedirect,
    isShowStaticBanner,
    label,
    multipleBannerOptions,
    setIsShowEmptySpace,
    transferSuccess,
    isDealsHidesPrice,
  } = props;

  const [isShowDeals, setIsShowDeals] = useState(false);

  const {
    media: { isNotInitMobile },
  } = useContext(MediaAuthContext);

  const { setPanelStatus, isShowFooter } = useContext(LayoutContext);

  let timerId = null;

  useEffect(() => {
    if (checkStatus() && dealsSection === "true") {
      isNotInitMobile ? showDealsBannerMobile() : showDealsBannerDesktop();
      document.addEventListener("scroll", showDealsBanner);
    }

    return () => {
      document.removeEventListener("scroll", showDealsBanner);
    };
  }, [isNotInitMobile]);

  useEffect(() => {
    if (!isNotInitMobile && isShowStaticBanner !== undefined) {
      setIsShowDeals(!isShowStaticBanner);
    }
    setPanelStatus(isShowDeals);
    setIsShowEmptySpace && setIsShowEmptySpace(isShowDeals);
  }, [isShowDeals, isShowStaticBanner]);

  const checkStatus = () =>
    !sessionStorage.getItem(dealsVersion) || alwaysShowDeals;

  const handleSuccess = () => {
    setIsShowDeals(false);
    setIsShowEmptySpace && setIsShowEmptySpace(false);
    transferSuccess && transferSuccess();
  };

  const showDealsBanner = () => {
    isNotInitMobile && showDealsBannerMobile();
  };

  const showDealsBannerMobile = () => {
    if (checkStatus()) {
      setIsShowDeals(window.scrollY > 290);
    } else {
      isShowDeals && setIsShowDeals(false);
      document.removeEventListener("scroll", showDealsBannerMobile);
    }
  };

  const sendEvents = (status) => {
    const action = status ? "open" : "close";
    const labelEvents = label ?? "Get Holiday Deals!";
    const categoryEvents = category ?? "Index";

    track(categoryEvents, action, labelEvents);
    trackGa(action, { categoryEvents, labelEvents });
  };

  const showDealsBannerDesktop = () => {
    if (!checkStatus()) return;

    if (multipleBannerOptions) {
      setIsShowDeals(!isShowStaticBanner);
      return;
    }

    if (!window.isEnabledTimer) {
      window.isEnabledTimer = true;
      timerId = setTimeout(() => {
        setIsShowDeals(true);
        window.isEnabledTimer = false;
      }, 1000);
    } else {
      clearTimeout(timerId);
      setIsShowDeals(false);
      window.isEnabledTimer = false;
    }
  };

  const handlerCloseButton = () => {
    sendEvents(alwaysShowDeals);

    if (!alwaysShowDeals) {
      sessionStorage.setItem(dealsVersion, "closed");
      setIsShowDeals(false);
      setIsShowEmptySpace && setIsShowEmptySpace(false);
    }
  };

  const clickHandler = (e) => {
    !closureOnlyCross && sessionStorage.setItem(dealsVersion, "closed");

    if (
      e.target.id === "js-close-panel" ||
      e.target.parentNode.id === "js-close-panel"
    ) {
      handlerCloseButton();
    } else if (!disableRedirect) {
      sendEvents(closureOnlyCross || alwaysShowDeals);
      window.open(dealsUrl, "_blank");
    }
    if (!alwaysShowDeals && !disableRedirect && !closureOnlyCross) {
      setIsShowEmptySpace && setIsShowEmptySpace(false);
      setIsShowDeals(false);
    }
  };
  return (
    <>
      {React.cloneElement(children, {
        isShowDeals: isDealsHidesPrice ? false : isShowDeals,
        clickOpenHandler: clickHandler,
        onSuccess: handleSuccess,
        isBottomLine: isShowFooter,
      })}
    </>
  );
};

DealsBannerContainer.propTypes = {
  category: PropTypes.string,
  children: PropTypes.object.isRequired,
  closureOnlyCross: PropTypes.bool,
  dealsSection: PropTypes.string.isRequired,
  dealsUrl: PropTypes.string.isRequired,
  dealsVersion: PropTypes.string.isRequired,
  disableRedirect: PropTypes.bool,
  label: PropTypes.string,
  transferSuccess: PropTypes.func,
};

export default DealsBannerContainer;
